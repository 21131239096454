
export const formRules = {
    required: (v: any) => {
        return (!!v || v === 0 || v === "0") || 'Field is required'
    },
    validEmail: (v:string) => {
        return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(v) || 'E-mail must be valid'
    },
    maxLength: (length: number) => (v: string) => {
        return v.length <= length || `Max ${length} characters`
    },
    positiveNumber: (v: string) => {
        return /^\d+$/.test(v) || 'Must be a positive number'
    }
}